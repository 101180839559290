import React from 'react';
import 'normalize.css';
import '../src/styles/global.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-notifications/lib/notifications.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rc-slider/assets/index.css';
import { NotificationContainer } from 'react-notifications';
import AuthContextProvider from '../src/context/AuthContext';
import { BasketContextProvider } from '../src/context/BasketContext';

function MyApp({ Component, pageProps }) {
  return (
    <>
      <AuthContextProvider>
        <BasketContextProvider>
          <Component {...pageProps} />
        </BasketContextProvider>
      </AuthContextProvider>
      <NotificationContainer />
    </>
  );
}

export default MyApp;
