import api from '../lib/api';

const basketSources = {
  root: '/basket',
  detail: (id) => `/basket/${id}`,

  addProduct: async (product) => {
    const response = await api.post('/basket', product);

    return response.data;
  },

  getBasket: async () => {
    const response = await api.get('/basket');

    return response.data;
  },

  deleteProduct: async (id) => {
    const response = await api.delete(`/basket/${id}`);

    return response;
  },

  deleteAll: async () => {
    const response = await api.delete('/basket');

    return response;
  },
};

export default basketSources;
