import axios from 'axios';
import { get } from 'lodash';
import { getCookie, getUserToken, removeUserCookie } from './cookie';
import routes from '../helpers/routes';
import { COOKIE } from '../constants/COOKIE';
import emmiter from './emmiter';
import EVENTS from '../constants/EVENTS';

const Api = axios.create({
  baseURL: `${process.env.BACKEND_API_URL || process.env.API_URL}/api/`,
  responseType: 'json',
  withCredentials: true,
});

const buildHeaders = () => {
  const token = getUserToken();
  const headers = {};

  if (!token) return headers;

  return {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
};

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      typeof window !== 'undefined' &&
      get(error, 'response.status') === 401 &&
      (window.location.pathname.includes('/profile') ||
        window.location.pathname.includes('/business'))
    ) {
      removeUserCookie();
      window.location = routes.rootLogin;

      emmiter.emit(EVENTS.OPEN_AUTH_MODAL);
    }

    if (typeof window !== 'undefined' && get(error, 'response.status') === 401) {
      removeUserCookie();
      emmiter.emit(EVENTS.OPEN_AUTH_MODAL);
    }

    return Promise.reject(error);
  },
);

const buildOptions = ({ params, headers, ...options }) => {
  const deliveryId = getCookie(COOKIE.DELIVERY_DISTRICT_ID);

  if (deliveryId) {
    params = params || {};
    params.districtId = deliveryId;
  }

  return {
    headers: { ...buildHeaders(), ...headers },
    params: { ...params },
    ...options,
  };
};

const api = {
  get: (url, options = { params: {} }) => {
    const opts = buildOptions(options);
    return Api.get(url, opts);
  },
  post: (url, data, options = {}) => {
    const opts = buildOptions(options);
    return Api.post(url, data, opts);
  },
  patch: (url, data, options = {}) => {
    const opts = buildOptions(options);
    return Api.patch(url, data, opts);
  },
  delete: (url, options = {}) => {
    const opts = buildOptions(options);
    return Api.delete(url, opts);
  },
};

export default api;
