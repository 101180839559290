const EVENTS = {
  ADD_TO_CART: 'ADD_TO_CART',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SHOW_CLEAR_CART_MODAL: 'SHOW_CLEAR_CART_MODAL',
  OPEN_AUTH_MODAL: 'OPEN_AUTH_MODAL',
  CLOSE_AUTH_MODAL: 'CLOSE_AUTH_MODAL',
  OPEN_MODAL_DELIVERY_DAY: 'OPEN_AUTH_MODAL_DELIVERY_DAY',
  OPEN_MOBILE_NAVIGATION: 'OPEN_MOBILE_NAVIGATION',

  OPEN_MODAL_FARMER_REGISTRATION: 'OPEN_MODAL_FARMER_REGISTRATION',
  OPEN_GEO_MODAL: 'OPEN_GEO_MODAL',

  CREATE_ORDER: 'CREATE_ORDER',
  OPEN_POPUP: 'OPEN_POPUP',
  OPEN_MOBILE_SEARCH_MODAL: 'OPEN_MOBILE_SEARCH_MODAL:',
  OPEN_MOBILE_FILTER: 'OPEN_MOBILE_FILTER',
  OPEN_LEAVE_FEEDBACK: 'OPEN_LEAVE_FEEDBACK',
  FEEDBACK_CREATED: 'FEEDBACK_CREATED',

  CLOSE_ADD_ADDRESS_PROFILE: 'CLOSE_ADD_ADDRESS_PROFILE',

  LOG_BY_NOT_MAIL: 'LOG_BY_NOT_MAIL',
  LOG_BY_MAIL: 'LOG_BY_MAIL',
  SET_USER: 'SET_USER',

  SELECTED_DATE_FORM: 'SELECTED_DATE_FORM',

  SORT_RULE_SELECTED: 'SORT_RULE_SELECTED',
  GET_UP: 'GET_UP',
};

export default EVENTS;
