import React, { createContext, useEffect, useState } from 'react';
import { getUser } from '../helpers/authUser';
import { removeCookie } from '../lib/cookie';
import emmiter from '../lib/emmiter';
import EVENTS from '../constants/EVENTS';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = getUser();

    setUser(user);

    const setCurrentUser = (user) => {
      setUser(user);
    };

    emmiter.addListener(EVENTS.SET_USER, setCurrentUser);

    return () => {
      emmiter.removeListener(EVENTS.SET_USER, setCurrentUser);
    };
  }, []);

  const saveUserData = (data) => {
    setUser(data);
  };

  const logout = () => {
    removeCookie('token');
    removeCookie('user');
    setUser(null);
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ user, saveUserData, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
