import cookies from 'js-cookie';

export const getUserToken = () => {
  const token = cookies.get('token');
  if (!token) {
    return null;
  }
  return token;
};

export const setUserCookie = (key, user) => {
  cookies.set(key, user, {
    expires: 1,
  });
};

export const getCookie = (token) => {
  const cookie = cookies.get(token);
  if (!cookie) {
    return null;
  }
  return cookie;
};

export const setCookie = (token, value, expires = 1) => {
  cookies.set(token, value, {
    expires,
  });
};

export const removeUserCookie = () => cookies.remove('auth');

export const removeCookie = (key) => cookies.remove(key);
