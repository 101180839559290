import { isEmpty } from 'lodash';
import { ROLES } from '../constants/ROLES';
import { getCookie } from '../lib/cookie';
import routes from './routes';

export const getUserCompany = () => {
  try {
    const user = JSON.parse(getCookie('user'));

    if (
      isEmpty(user) &&
      typeof window !== 'undefined' &&
      window.location.pathname.includes('/business')
    ) {
      return (window.location = routes.rootLogin);
    }

    return user.storeMember?.company;
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const getUser = () => {
  try {
    const user = JSON.parse(getCookie('user'));

    return user;
  } catch (error) {
    return null;
  }
};

export const userIsOwner = (user) => {
  return user && user.storeMember?.role === ROLES.OWNER;
};
