import { parse, stringify } from 'query-string';
import Router from 'next/router';

const routes = {
  root: '/',
  rootLogin: '/?login=true',
  login: '/business/login',
  restore: '/restore',
  register: '/register',
  cart: '/cart',
  faq: '/faq',
  contacts: '/contacts',
  detail: '/products/details',

  reviews: '/profile/reviews',
  profile: '/profile',
  profileFavorites: '/profile/favorites',
  profileOrders: '/profile/orders',
  prodileDeliveryAddress: '/profile/addressDelivery',
  profileContacts: '/profile/contacts',
  profileChangePassword: '/profile/changePassword',

  orders: '/orders',

  farmersCatalog: '/farmers/farmerCatalog',
  search: (query) => `/search/${query}`,
  farmers: '/farmers',
  farmer: (id) => `/farmers/${id}`,

  store: (id) => `/stores/${id}`,
  openStore: '/open-store',

  products: '/products',
  productsCategory: (category) => `/products/${category}`,
  product: (category, subcategory, slug) => `/products/${category}/${subcategory}/${slug}`,
  productsSubCategory: (category, subcategory) => `/products/${category}/${subcategory}`,

  discounts: '/discounts',
  discountsCategory: (category) => `/discounts/${category}`,

  basket: '/basket',

  flatpage: (id) => `/pages/${id}`,

  farmerfilter: '/farmers/farmerFilter/farmerFilter',
  farmerCard: '/farmers/farmercard/farmercard',

  deliveryRegistration: '/delivery-registration',
};

export default routes;

export const businessRoutes = {
  team: '/business/team',
  teamDetail: (id) => `/business/team/${id}`,
  stores: '/business/stores',
  storesNew: '/business/stores/new',
  products: '/business/products',
  productsNew: '/business/products/new',
  productDetail: (id) => `/business/products/${id}`,
  store: (id) => `/business/stores/${id}`,
  storeProducts: (id) => `/business/stores/${id}/products`,
  storeProduct: (id, productId) => `/business/stores/${id}/products/${productId}`,
  newProduct: (id) => `/business/stores/${id}/products/new`,
  certificate: (id) => `/business/certificate/${id}`,
  certificatePage: `/business/certificate`,
  certificateNew: '/business/certificate/new',
  orders: `/business/orders`,

  order: (id) => `/business/orders/${id}`,
};

export const routesPatterns = {
  store: '/stores/[id]',
  farmer: '/farmers/[id]',
  product: '/products/[category]/[subcategory]/[slug]',
  productsCategory: '/products/[category]',
  discountsCategory: '/discounts/[category]',
  flatpage: '/pages/[id]',
  productsSubCategory: '/products/[category]/[subcategory]',
  search: '/search/[query]',
};

export const businessRoutesPatterns = {
  store: '/business/stores/[id]',
  storeProducts: '/business/stores/[id]/products',
  storeProduct: '/business/stores/[id]/products/[productId]',
  storeNewProducts: '/business/stores/[id]/products/new',
  products: '/business/products/[id]',
  orders: '/business/orders/[id]',
  teamDetail: `/business/team/[id]`,
  certificate: `/business/certificate/[id]`,
};

export const getQuery = (route) => {
  const qs = route.split('?')[1];
  return qs ? parse(qs, { arrayFormat: 'comma' }) : {};
};

export const replaceCurrentQuery = ({ pathname, query }) =>
  Router.replace(
    {
      query,
      pathname,
    },
    `${window.location.pathname}?${stringify(query)}`,
  );
