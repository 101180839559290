export const ROLES = {
  OWNER: 'Owner',
  MANAGER: 'Manager',
  COURIER: 'Courier',
};

export const ROLES_NAMES = {
  [ROLES.OWNER]: 'Owner',
  [ROLES.MANAGER]: 'Manager',
  [ROLES.COURIER]: 'Courier',
};

export const ROLES_OPTIONS = [
  { id: ROLES.MANAGER, name: ROLES_NAMES[ROLES.MANAGER] },
  { id: ROLES.COURIER, name: ROLES_NAMES[ROLES.COURIER] },
];
