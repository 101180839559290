import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import basketSources from '../sources/basketSources';
import emmiter from '../lib/emmiter';
import EVENTS from '../constants/EVENTS';
import { formatPrice } from '../helpers/prices';

const getProductsTotalPrice = (basket) => {
  if (!basket?.products) return 0;

  const sum = basket?.products?.ids.reduce((acc, id) => {
    const product = basket.products[id];

    return acc + product.count * product.price;
  }, 0);

  return formatPrice(sum);
};

const INITIAL_STATE = {
  state: {
    isLoading: true,
    isSuccess: false,
  },
  data: {},
  productsPrice: 0,
};

export const BasketContext = React.createContext(INITIAL_STATE);

export const BasketContextProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    async function getBasket() {
      const data = await basketSources.getBasket();

      setState((prevState) => ({
        ...prevState,
        data,
        productsPrice: getProductsTotalPrice(data),
        state: {
          isLoading: false,
        },
      }));
    }

    getBasket();
  }, []);

  const deleteBasketProduct = async (id) => {
    const basket = await basketSources.deleteProduct(id);

    setState((prevState) => ({
      ...prevState,

      data: basket.data,
    }));
  };

  const removeAllProducts = async () => {
    await basketSources.deleteAll();

    setState((prevState) => ({
      ...prevState,
      productsPrice: 0,
      data: null,
    }));
  };

  const updateBasketProduct = async (product, count) => {
    try {
      const basket = await basketSources.addProduct({ ...product, count });

      setState((prevState) => {
        if (!basket.products.ids.length) {
          return {
            data: {},
            productsPrice: 0,
          };
        }

        return {
          ...prevState,
          data: {
            ...prevState.data,
            ...basket,
          },
          productsPrice: getProductsTotalPrice(basket),
        };
      });
    } catch (error) {
      NotificationManager.error('Не удалось обновить корзину');
    }
  };

  const setSuccessBasket = () => {
    setState((prevState) => ({
      ...prevState,
      data: {},
      state: {
        isLoading: false,
        isSuccess: true,
      },
    }));
  };

  const resetBasketState = () => {
    setState((prevState) => ({
      ...prevState,
      state: {
        isLoading: true,
        isSuccess: false,
      },
    }));
  };

  const allProductsAvailableForDelivery = (deliveryFilter) => {
    return state?.data?.products?.ids?.every((id) => {
      return state.data.products[id][deliveryFilter];
    });
  };

  const daysAreDelivered = (deliveryFilter) => {
    return state.data.products?.ids
      ?.map((id) => {
        const daysDelivered = state.data.products[id][deliveryFilter];
        if (!daysDelivered) {
          return daysDelivered;
        }
      })
      .filter((item) => item !== undefined);
  };

  return (
    <BasketContext.Provider
      value={{
        ...state,
        updateBasketProduct,
        setSuccessBasket,
        resetBasketState,
        deleteBasketProduct,
        removeAllProducts,
        allProductsAvailableForDelivery,
        daysAreDelivered,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
